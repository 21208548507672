import React from "react"
import { graphql } from "gatsby"

import "./index.scss";
import * as styles from './index.module.scss';

import Layout from "../page-components/layout"
import SEO from "../page-components/seo"

import Header2 from '../images/header2.jpg';
import KalendarSVG from '../icons/envato/kalender.svg';
import WissenSVG from '../icons/envato/wissen.svg';
import CompanySVG from '../icons/envato/company.svg';
import GardenSVG from '../icons/envato/immoEvaluation.svg';
import StrategySVG from '../icons/envato/strategy.svg';
import KalendarNewSVG from "../icons/envato/kalendarNew.svg";

import ArrowLink from "../helper-components/arrow-link/arrow-link";
import RcCard from "../components/card/rcCard"
import { Link } from "gatsby";
import IndexBetriebsmarkt from "../pages-parts/index/index-betriebsmarkt"
import { DateTime } from "luxon"


const IndexPage = ( { data } ) => {

  const latestImmos = data.allStrapiImmos.nodes
    .filter(n => !!n)
    .map(
      n => {
        return {
          type: 'immo',
          date: DateTime.fromISO(n.created_at),
          data: n
        }
      }
    );

  const latestWpWissen = data.allWpWissen.nodes
    .filter(n => !!n)
    .map(
      n => {
        return {
          type: 'wissen',
          date: DateTime.fromISO(n.modified),
          data: n
        }
      }
    );

  const latestWpEvents = data.allWpEvent.nodes
    .filter(n => !!n)
    .map(
      n => {
        return {
          type: 'event',
          data: n,
          date: DateTime.fromISO(n.modified)
        }
      }
    );

  const latestWpPages = data.allWpPage.nodes
    .filter(n => !!n)
    .map(
      n => {
        return {
          type: 'page',
          data: n,
          date: DateTime.fromISO(n.modified)
        }
      }
    );

  const latestAll = [
    ...latestImmos,
    ...latestWpWissen,
    ...latestWpEvents,
    ...latestWpPages
  ].sort(
    (a, b) => {
      return b.date.toMillis() - a.date.toMillis()
    }
  );

  return <Layout>

    <SEO title="Startseite"/>

    <div className="teaser teaser-white" style={{ backgroundImage: "url(" + Header2 + ")" }}>

      <div className="teaser-gradient teaser-gradient-variant1"/>

      <div className={"container pt-7 teaserContent"}>
        <div className="row">
          <div className="col-12 col-xl-7">

            <div
              className={styles.acfIntro}
              dangerouslySetInnerHTML={
                {
                  __html: data.wp.texteFRSektionen.acf.indexpage
                }
              }/>

          </div>
        </div>
      </div>

    </div>

    <div className="primary">

      <div className="rc-lastest-container container rc-container shadow-xl">


        <div className="d-flex align-items-center mb-4">
          <h4>
            <KalendarNewSVG className="mr-2 svgFill"/>
            Neueste Beiträge
          </h4>
        </div>

        <div className="row">

          {latestAll.slice(0, 4).map(
            a => {
              const n = a.data;
              const i = a.data;

              if (a.type === "wissen") {
                return <RcCard to={"/wissen/" + n.slug} caption={n.title} className={"mb-4"} innerClassName={"h-100"}
                               description={n.acf_elements?.cardthumbnailtext}
                               img={n.acf_elements?.header?.localFile?.childImageSharp?.gatsbyImageData}/>;
              } else if (a.type === "event") {
                return <RcCard to={"/events/" + n.slug} className={"mb-4"} caption={n.title} innerClassName={"h-100"}
                               containImage={true}
                               description={n.acf_elements?.cardthumbnailtext}
                               img={n.acf_elements?.header?.localFile?.childImageSharp?.gatsbyImageData}/>;
              } else if (a.type === "page") {
                return <RcCard to={n.slug}
                        caption={n.title}
                        className={"mb-4"}
                        innerClassName={"h-100"}
                        description={n.acf.description}
                        img={n.acf?.thumbnail?.localFile?.childImageSharp?.gatsbyImageData}/>
              } else if (a.type === "immo") {
                if (i.Anhang.length === 0) return null;

                let headerImage = i.Anhang.find(a => a.gruppe === "BILD" && a.daten);
                if (!headerImage) headerImage = i.Anhang[0];
                return <RcCard style={'small'} innerClassName={"h-100"} to={'/immobilien/' + i.id} className={"mb-4"} caption={i.title}
                               img={headerImage.daten?.localFile?.childImageSharp?.gatsbyImageData} />;
              }

            }
          )}

        </div>




        <div className="d-flex align-items-center mt-5 mb-4">
          <Link to={"/beratung"}>
            <h4>
              <StrategySVG className="mr-2"/>
              Beratung
            </h4>
          </Link>

          <ArrowLink to={"/beratung"}
                     hideMobile={true}
                     className="ml-auto"
                     text={"Alle Beratungs-Pakete"} />
        </div>

        <div className="row">

          {data.allWpPage.nodes.filter(n => n.acf.art === "beratung").slice(0, 4).map(
            n => <RcCard to={n.slug}
                         caption={n.title}
                         className={"mb-4"}
                         innerClassName={"h-100"}
                         description={n.acf.description}
                         img={n.acf?.thumbnail?.localFile?.childImageSharp?.gatsbyImageData}/>
          )}

        </div>


        <div className="d-flex align-items-center mt-5 mb-4">
          <Link to={"/wissen"}>
            <h4>
              <WissenSVG className="mr-2"/>
              Wissen
            </h4>
          </Link>

          <ArrowLink to={"/wissen"} className={"ml-auto"}
                     hideMobile={true}
                     text={"Alle Beiträge"}/>
        </div>
        <div className="row">

          {data.allWpWissen.nodes.map(
            n => <RcCard to={"/wissen/" + n.slug} caption={n.title} className={"mb-4"} innerClassName={"h-100"}
                         description={n.acf_elements?.cardthumbnailtext}
                         img={n.acf_elements?.header?.localFile?.childImageSharp?.gatsbyImageData}/>
          )}

        </div>


        <div className="d-flex align-items-center mb-4 mt-5">

          <Link to={"/events"}>
            <h4>
              <KalendarSVG className="mr-2"/>
              Events und Webinare
            </h4>
          </Link>


          <ArrowLink to={"/events"}
                     hideMobile={true}
                     className="ml-auto" text={"Alle Events"}/>
        </div>
        <div className="row">

          {data.allWpEvent.nodes.map(
            n => <RcCard to={"/events/" + n.slug}
                         description={n.acf_elements?.cardthumbnailtext}
                         className={"mb-4"}
                         caption={n.title}
                         innerClassName={"h-100"}
                         containImage={true}
                         img={n.acf_elements?.header?.localFile?.childImageSharp?.gatsbyImageData}/>
          )}

        </div>


        <div className="d-flex align-items-center mb-4 mt-5">

          <Link to={"/immoservice"}>
            <h4>
              <GardenSVG className="svgFill mr-2"/>
              Immobilienverwaltung und Immobilien Services
            </h4>
          </Link>

          <ArrowLink className={"ml-auto"} to={"/immoservice"}
                     hideMobile={true}
                     text={"Alle Immobilien Services"}/>
        </div>
        <div className="row">

          {data.allWpPage.nodes.filter(n => n.acf.art === "immo").slice(0, 4).map(
            n => <RcCard to={n.slug}
                         caption={n.title}
                         className={"mb-4"}
                         innerClassName={"h-100"}
                         description={n.acf.description}
                         img={n.acf?.thumbnail?.localFile?.childImageSharp?.gatsbyImageData}/>
          )}

        </div>


        <div className="d-flex align-items-center mt-5 mb-4">

          <Link to={"/immobilien"}>
            <h4>
              <GardenSVG className="mr-2 svgFill"/>
              Neueste Immobilien
            </h4>
          </Link>

          <Link to={"/immobilien"} className="ml-auto d-none d-lg-block">
            <ArrowLink text={"Alle Immobilien"}/>
          </Link>

        </div>
        <div className="row">

          { data.allStrapiImmos.nodes.map(
            (i) => {
              if (i.Anhang.length === 0) return null;

              let headerImage = i.Anhang.find(a => a.gruppe === "BILD" && a.daten);
              if (!headerImage) headerImage = i.Anhang[0];
              return <RcCard style={'small'} innerClassName={"h-100"} to={'/immobilien/' + i.id} className={"mb-4"} caption={i.title}
                             img={headerImage.daten?.localFile?.childImageSharp?.gatsbyImageData} />;
            }
          )}

        </div>


        <div className="d-flex align-items-center mb-4 mt-5">

          <Link to={"/gutachten"}>
            <h4>
              <CompanySVG className="mr-2"/>
              Gutachten
            </h4>
          </Link>

          <ArrowLink
            hideMobile={true}
            to={"/gutachten"} className="ml-auto" text={"Alle Gutachten-Schwerpunkte"}/>
        </div>

        <div className="row">

          {data.allWpPage.nodes.filter(n => n.acf.art === "gutachten").slice(0, 4).map(
            n => <RcCard to={n.slug}
                         caption={n.title}
                         className={"mb-4"}
                         innerClassName={"h-100"}
                         description={n.acf.description}
                         img={n.acf?.thumbnail?.localFile?.childImageSharp?.gatsbyImageData}/>
          )}

        </div>


        <IndexBetriebsmarkt className={"mt-5"}/>

      </div>

    </div>


  </Layout>
};

export default IndexPage;

export const query = graphql`
  query IndexPageQuery {
       
    wp {
      texteFRSektionen {
          acf {
            indexpage
          }
      }
    }
    
    allStrapiImmos(limit: 4, sort: { fields:created_at, order:DESC }, filter: { title: { ne: "EMPTY" } } ) {
      nodes {
        id
        title
        created_at
        Anhang {
          gruppe
          daten {
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
      }
    }
       
   
  
    allWpWissen(limit: 4) {
      nodes {
        id
        slug
        title
        content
        modified
        acf_elements {
          description
          cardthumbnailtext
          header {
            mediaItemUrl
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
      }
    }
    
    allWpEvent(limit: 4) {
      nodes {
          slug
          title
          content
          modified
          acf_elements {
            cardthumbnailtext
            header {
              localFile {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
          }
      }
    }
    
    allWpPage(filter: {acf: {art: {in: [ "gutachten", "beratung", "immo" ] }}}) {
      nodes {
        modified
        acf {
          art
          description
          thumbnail {
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
        slug
        title
      }
    }
    
    
  }
    
    
`
