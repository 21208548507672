import React from 'react';
import './arrow-link.scss';
import { Link } from "gatsby";

import ArrowRightSVG from '../../icons/fontawesome/light/arrow-right.svg';

const ArrowLink = ( { to, text, className = '', hideMobile = false } ) => {

  const additionalCSS = hideMobile ? " d-none d-lg-flex " : " d-flex ";

  return <Link to={to} className={" c-pointer align-items-center arrowLink " + className + " " + additionalCSS} >
    <ArrowRightSVG className={"svgFill"} />
    {text}
  </Link>

};

export default ArrowLink;
