import React, { useEffect, useState } from "react"
import axios from "axios";
import { BZH_BUCKET_URL, BZH_GET_URL } from "../../helper/constants"
import GardenSVG from "../../icons/envato/immoEvaluation.svg"
import { Link } from "gatsby";
import ArrowLink from "../../helper-components/arrow-link/arrow-link"
import RcCard from "../../components/card/rcCard"

import MeinNachfolgerImg from "../../images/meine-nachfolger-suche.jpg";
import MeineUnternehmenImg from "../../images/meine-unternehmens-suche.jpg";

const IndexBetriebsmarkt = ( { className = '' }) => {

  const [ data, setData ] = useState();

  useEffect(
    () => {

      const action = async () => {
        try {
          const res = await axios.get(BZH_GET_URL);
          setData(res.data);
        } catch(err) {
          console.log(err);
        }
      };

      action();

    },
    []
  );

  if (!data) {
    return null;
  }

  return <div className={className}>
        <div className="d-flex align-items-center mb-4">

          <Link to={"/betriebsmarkt?offer=-1"}>
            <h4>
              <GardenSVG className="mr-2 svgFill" />
              Betriebsmarkt-Angebote
            </h4>
          </Link>

          <ArrowLink to={"/betriebsmarkt?offer=-1"} className="ml-auto d-none d-lg-block"
            text={"Alle Betriebsangebote"} />
        </div>

        <div className="row">
          {
            data.ownOffers?.filter( (s, idx) => idx < 3).map(
              (i) => {
                const mainPicture = BZH_BUCKET_URL + "/" + i.mainpicture?.path;

                return <RcCard key={i.id} style={'small'} innerClassName={"h-100"} to={'/betriebsmarkt?offer=' + i.id}
                               className={"mb-4"} caption={i.title} img={mainPicture} />;
              }
            )
          }

          <RcCard style={"small"} innerClassName={"h-100"}
                  to={"/betriebsmarkt-contact-searching"}
                  className={"mb-4"} innerClassName={"h-100"} caption={"Kein geeignetes Unternehmen gefunden? Dann senden Sie uns bitte Ihre Suchanfrage."}
                  img={MeineUnternehmenImg} />
        </div>

    <div className="d-flex align-items-center my-4">

      <Link to={"/betriebsmarkt?searching=-1"}>
        <h4>
          <GardenSVG className="mr-2 svgFill" />
          Betriebsmarkt-Suchprofile
        </h4>
      </Link>


      <ArrowLink text={"Alle Betriebssuchen"} to={"/betriebsmarkt?searching=-1"} className="ml-auto" />
    </div>

      <div className="row">
        {
          data.searchings?.filter( (s, idx) => idx < 3).map(
            (s) => {
              return <RcCard key={s.id} style={'small'} innerClassName={"h-100"} to={"/betriebsmarkt?searching=" + s.id}
                             className={"mb-4"} caption={s.title} img={"/searching.jpg"} />
            }
          )
        }

        <RcCard style={"small"} innerClassName={"h-100"}
                to={"/betriebsmarkt-contact-offer"}
                className={"mb-4"} caption={"Kein geeignetes Suchprofil bzw. Nachfolger für Sie vorhanden? Dann senden Sie uns bitte ihr Unternehmensprofil "}
                img={MeinNachfolgerImg} />

      </div>
  </div>;

};

export default IndexBetriebsmarkt;
